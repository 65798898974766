// Generated by Framer (7f69244)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sL4lqFQm2", "VI3YSptFs"];

const serializationHash = "framer-K4uC4"

const variantClassNames = {sL4lqFQm2: "framer-v-47d13y", VI3YSptFs: "framer-v-1mrmkfc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "sL4lqFQm2", Phone: "VI3YSptFs"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sL4lqFQm2"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sL4lqFQm2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "augiA20Il"}} nodeId={"sL4lqFQm2"}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 50, pixelWidth: 155, src: "https://framerusercontent.com/images/qJq5ZSP2HO25rZ37xIGJRtU7y3Q.svg"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-47d13y", className, classNames)} framer-189d7eu`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"sL4lqFQm2"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({VI3YSptFs: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-K4uC4.framer-189d7eu, .framer-K4uC4 .framer-189d7eu { display: block; }", ".framer-K4uC4.framer-47d13y { height: 50px; overflow: visible; position: relative; text-decoration: none; width: 155px; }", ".framer-K4uC4.framer-v-1mrmkfc.framer-47d13y { aspect-ratio: 3.0833333333333335 / 1; height: var(--framer-aspect-ratio-supported, 48px); width: 148px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 155
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"VI3YSptFs":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerB0DyE6Mcz: React.ComponentType<Props> = withCSS(Component, css, "framer-K4uC4") as typeof Component;
export default FramerB0DyE6Mcz;

FramerB0DyE6Mcz.displayName = "Brand Logo Wide";

FramerB0DyE6Mcz.defaultProps = {height: 50, width: 155};

addPropertyControls(FramerB0DyE6Mcz, {variant: {options: ["sL4lqFQm2", "VI3YSptFs"], optionTitles: ["Desktop", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerB0DyE6Mcz, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})